import { Stack, Typography } from "@mui/material";
const AccroDescription = () => {
    return (
        <Stack>
        <Typography sx={styles.title}>{"Bienvenue chez le Corsair Volant"}</Typography>
        {/* <Typography sx={styles.H2}>{"Votre Aventure Commence au Lac des Vieilles Forges"}</Typography> */}

        <Typography sx={styles.H3}>{"Êtes-vous prêts à relever le défi"}</Typography>
        <Typography sx={styles.p}>{"De la cale du navire jusqu’à la vigie, au sommet du plus haut des mâts, plongez dans le monde de la piraterie et devenez un… CORSAIRE VOLANT !"}</Typography>
        <Typography sx={styles.listeElement}>{" - 70 ateliers aériens (de 1 à 15 mètres au dessus du sol)"}</Typography>
        <Typography sx={styles.listeElement}>{" - 9 tyroliennes (distance totale de 500m)"}</Typography>
        <Typography sx={styles.listeElement}>{" - 1 km de piste (pour jouer en toute quiétude)"}</Typography>
        <Typography sx={styles.listeElement}>{" - 10 parcours adaptés aux envies et aux niveaux de chacun."}</Typography>

        <Typography sx={styles.H3}>{"Les conseils d’une équipe qualifiée"}</Typography>
        <Typography sx={styles.p}>{"’encadrement de l’activité est assuré par des moniteurs qualifiés qui vous équipent, vous dispensent les consignes de sécurité et d’utilisation du matériel."}</Typography>
        <Typography sx={styles.listeElement}>{" - Se vêtir de vêtements sportifs. Éviter absolument les jupes, les robes ainsi que les foulards et écharpes."}</Typography>
        <Typography sx={styles.listeElement}>{" - Prévoyez des chaussures fermées type baskets. Pas de sandales ou de tongs !"}</Typography>
        <Typography sx={styles.listeElement}>{" - Pour davantage de confort, vous pouvez prévoir une paire de gant type cycliste."}</Typography>
        <Typography sx={styles.listeElement}>{" - A l’arrivée sur le parc, prenez connaissance des règles de sécurité et du règlement intérieur"}</Typography>
        <Typography sx={styles.listeElement}>{" - Pensez à apporter de l’eau pour vous hydrater (pas d’eau potable sur le parc sauf à la vente)."}</Typography>


</Stack>
    )
};

export default AccroDescription;

const styles = {
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Afacad',
        color: '#00463E',
        textTransform: 'uppercase',
        margin: 'auto',
        marginBottom: 4,

    },
    H2: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    H3: {
        fontWeight: 'bold',
        fontSize: 22,
        fontStyle: 'italic',
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    p:{
        fontSize: 20,
        marginBottom: 2,
    fontFamily: 'Afacad'

    },
    listeElement: {
        fontSize: 20,
        marginLeft: 4,
        fontFamily: 'Afacad'

    }
}
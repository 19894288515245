import { Stack, Typography } from "@mui/material";
const TrotDescription = () => {
    return (
        <Stack>
        <Typography sx={styles.title}>{"Bienvenue chez le Corsair Volant"}</Typography>
        <Typography sx={styles.H2}>{"Votre Aventure Commence au Lac des Vieilles Forges"}</Typography>

        <Typography sx={styles.p}>{"De la cale du navire jusqu’à la vigie, au sommet du plus haut des mâts, plongez dans le monde de la piraterie et devenez un… CORSAIRE VOLANT !"}</Typography>
</Stack>
    )
};

export default TrotDescription;

const styles = {
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Afacad',
        color: '#00463E',
        textTransform: 'uppercase',
        margin: 'auto',
        marginBottom: 4,

    },
    H2: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    H3: {
        fontWeight: 'bold',
        fontSize: 22,
        fontStyle: 'italic',
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    p:{
        fontSize: 20,
        marginBottom: 2,
    fontFamily: 'Afacad'

    },
    listeElement: {
        fontSize: 20,
        marginLeft: 4,
        fontFamily: 'Afacad'

    }
}
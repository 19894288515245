import { Stack, Typography } from "@mui/material";
const HacheDescription = () => {
    return (
        <Stack>
        <Typography sx={styles.title}>{"Bienvenue au lancer de hache"}</Typography>

        <Typography sx={styles.p}>{"Le lancer de hache, c'est un peu comme le lancer de fléchettes, mais en beaucoup plus « badass ». Imaginez-vous en train de brandir une hache, prêt à la lancer sur une cible en bois avec toute la force de vos bras. C'est ça, le lancer de hache : un mélange de technique, de puissance et de fun !"}</Typography>
        <Typography sx={styles.p}>{"Pour réussir à planter la hache dans la cible, vous devez faire preuve d'une technique irréprochable. Vous devez lancer la hache de manière à ce qu'elle tourne sur elle-même pendant son vol, pour que la lame atteigne la cible plutôt que le manche. Cela demande de la précision, de la concentration et une bonne dose de talent !"}</Typography>
        <Typography sx={styles.p}>{"Bien sûr, le lancer de hache, ce n'est pas juste une activité sportive. C'est aussi une façon de se détendre et de s'amuser entre amis. Alors pourquoi ne pas nous retrouver pour un moment mémorable ?"}</Typography>
</Stack>
    )
};

export default HacheDescription;

const styles = {
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Afacad',
        color: '#00463E',
        textTransform: 'uppercase',
        margin: 'auto',
        marginBottom: 4,

    },
    H2: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    H3: {
        fontWeight: 'bold',
        fontSize: 22,
        fontStyle: 'italic',
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    p:{
        fontSize: 20,
        marginBottom: 2,
    fontFamily: 'Afacad'

    },
    listeElement: {
        fontSize: 20,
        marginLeft: 4,
        fontFamily: 'Afacad'

    }
}
import { Stack, Typography } from "@mui/material";
const ElfyDescription = () => {
    return (
        <Stack>
        <Typography sx={styles.title}>{"Bienvenue a l'Elfy Park"}</Typography>

        <Typography sx={styles.p}>{"Sur les hauteurs du lac des Vieilles Forges, au cœur des Ardennes, le Elfy Park propose, sur plus de 5 hectares, une multitude de jeux sur la thématique des légendes. C’est le parc de loisirs idéal pour une sortie en famille."}</Typography>
        <Typography sx={styles.H3}>{"L’univers du Efly Park"}</Typography>

        <Typography sx={styles.p}>{"Avec votre famille, vous pourrez apprendre d’innombrables secrets sur la grande famille des esprits de la forêt, des ruisseaux et des jardins."}</Typography>
        <Typography sx={styles.p}>{"Accessible aux enfants ainsi qu’au adultes, Elfy Park vous propose une multitude de jeux mêlant ludique et pédagogique : luge d’été, cabanes perchées, mer de filets, tyroliennes, toboggans, jeux sonores, espace contes…"}</Typography>

</Stack>
    )
};

export default ElfyDescription;

const styles = {
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Afacad',
        color: '#00463E',
        textTransform: 'uppercase',
        margin: 'auto',
        marginBottom: 4,

    },
    H2: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    H3: {
        fontWeight: 'bold',
        fontSize: 22,
        fontStyle: 'italic',
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    p:{
        fontSize: 20,
        marginBottom: 2,
    fontFamily: 'Afacad'

    },
    listeElement: {
        fontSize: 20,
        marginLeft: 4,
        fontFamily: 'Afacad'

    }
}
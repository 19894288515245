import logo from './logo.svg';
import { Grid, Box, Typography, Stack, Card, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import MainCard from './MainCard';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import DJI_0021 from './image/DJI_0021.JPG'
import elfy from './image/elfy-98.jpg';
import accro from './image/accrobranche.jpeg'
import canoe from './image/cae.jpeg';
import trotinette from './image/Apo.jpeg'
import hache from './image/350-WhatsApp Image 2023-04-27 at 16.33.53 (4).webp';
import KnowMoreModal from './KnowMoreModal';
import maroc from './image/maroc.webp';
import HomeIcon from '@mui/icons-material/Home';
import CircularProgress from '@mui/material/CircularProgress';

import './App.css';
import { isVisible } from '@testing-library/user-event/dist/utils';

const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: 'white',
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: 'FFFFFF',
  minHeight: '300px',
  height: 'auto',
  padding: '0px',
  boxShadow: theme.shadows[4],
  '&:hover': {
      boxShadow: theme.shadows[24]
      // transform: 'scale(102%)'
  }
}));

const fabStyle = {
  position: 'absolute',
  top: 50,
  left: 50,
  backgroundColor: '#00463E',
  '&:hover': {
    backgroundColor: '#00463E',
}
};

const screenSize = () => {
  console.log(window.innerWidth)
  return window.innerWidth
}

function App() {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activity, setActivity] = useState('');
  const setInvisible = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500)
    setIsVisible(false)
  };
  const handleOpenModal = (value) => {
    setOpen(true);
    setActivity(value)
  }
  console.log(screenSize())
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [url, setUrl] = useState('');
  const handleDisplayWebSite = (site_url) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000)
    setUrl(site_url)
    setIsVisible(true);
  }
  return (
    <>
      <Box sx={{
        backgroundColor: "#ffffff",
        minHeight: "100vh",
        height: 'auto',
        justifyContent: 'center',
        alignContent: 'center',
        justifyItems: 'center',
        hoverflow: 'hidden',
        position: 'sticky',
        paddingBottom: 20,
        }}>
          <Stack sx={{ pt: 10, pb: 10}}>
            <Button sx={fabStyle} variant="contained" startIcon={<HomeIcon/>} onClick={() => setInvisible()}>Accueil</Button>
              <Stack direction="column" sx={{ width: '100%', alignItems: 'center', mb: 4, mt: 2 }}>
                <Typography sx={{ fontSize: 30, color: '#00463E', fontStyle: 'italic', fontFamily: 'Afacad' }}> Bienvenue au</Typography>
                <Typography sx={{ fontSize: screenSize() < 500 ? 30 : 36, color: '#00463E', fontWeight: 700, textTransform: 'uppercase', fontFamily: 'Afacad'}}> Lac des vieilles Forges</Typography>
              </Stack>
          </Stack>
          {isLoading ?
            <Grid container sx={{ height: '90vh', alignContent: 'center', justifyContent: 'center'}}>
            <CircularProgress sx={{ color: '#00463E' }} />
            </Grid>
            :
            <Grid container sx={{ height: '100%', alignContent: 'center', justifyContent: 'center'}}>
              {!isVisible ?
              <><Grid item xs={1} sm={2} ></Grid><Grid item xs={10} sm={8}>
                  <Grid container sx={{ height: '100%', width: '100%', justifyContent: 'center' }}>
                    <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                      <Card sx={{
                        p: 0,
                        height: 'auto',
                        boxShadow: theme.shadows[4],
                        '&:hover': {
                          boxShadow: theme.shadows[24],
                          transform: 'scale(102%)'
                        }
                      }}>
                        {/* photo */}
                        <Stack sx={{ height: '200px', backgroundColor: '#efebe9', p: 0 }}>
                          <img
                            src={canoe}
                            alt='wisplash'
                            loading="lazy"
                            style={{ height: '200px' }} />
                        </Stack>
                        <Stack sx={{ pl: 4, pr: 4, pt: 2 }}>
                          <Typography sx={styles.title}>Cap Ardennes Events</Typography>
                          <Typography sx={styles.description}>{"Découvrez les joies des activités nautiques et d'aventure avec Cap Ardennes Events, votre expert en loisirs extérieurs situé sur les rives pittoresques du Lac des Vieilles Forges. Nous proposons une variété d'activités aquatiques et terrestres pour tous les âges et tous les niveaux d'expérience."}</Typography>
                        </Stack>
                        {/* sx={{ height:'auto', p: 1, backgroundColor: 'black', clipPath: 'polygon(0 15%, 100% 0%, 100% 100%, 0 100%)'}} */}
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                          <Button disableElevation variant="contained" sx={styles.discoverButton} onClick={() => handleDisplayWebSite('https://www.cap-ardennes-events.fr/')}> découvrir</Button>
                          <Button disableElevation variant="contained" sx={styles.knowMoreButton} onClick={() => handleOpenModal('CAE')}> en savoir +</Button>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                      <Card sx={{
                        p: 0,
                        height: 'auto',  
                        boxShadow: theme.shadows[4],
                        '&:hover': {
                          boxShadow: theme.shadows[24],
                          transform: 'scale(102%)'
                        }
                      }}>
                        <Stack sx={{ height: '200px', p: 0 }}>
                          <img
                            src={DJI_0021}
                            alt='wisplash'
                            loading="lazy"
                            style={{ height: '200px' }} />
                        </Stack>
                        <Stack sx={{ pl: 4, pr: 4, pt: 2 }}>
                          <Typography sx={styles.title}>Wisplash</Typography>
                          <Typography sx={styles.description}>{"Notre parc aquatique gonflable, Wisplash, est fait pour vous ! Glissez, sautez et éclaboussez-vous tout en profitant des magnifiques paysages des Ardennes. Nous sommes persuadés que nos activités plairont à tous les membres de la famille, des plus jeunes aux plus âgés."}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                          <Button disableElevation variant="contained" sx={styles.discoverButton} onClick={() => handleDisplayWebSite('https://www.wisplash.fr/')}> découvrir</Button>
                          <Button disableElevation variant="contained" sx={styles.knowMoreButton} onClick={() => handleOpenModal('WISPLASH')}> en savoir +</Button>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                      <Card sx={{
                        p: 0,
                        height: 'auto',
                        boxShadow: theme.shadows[4],
                        '&:hover': {
                          boxShadow: theme.shadows[24],
                          transform: 'scale(102%)'
                        }
                      }}>
                        <Stack sx={{ height: '200px', backgroundColor: '#efebe9', p: 0 }}>
                          <img
                            src={hache}
                            alt='wisplash'
                            loading="lazy"
                            style={{ height: '200px' }} />
                        </Stack>
                        <Stack sx={{ pl: 4, pr: 4, pt: 2 }}>
                          <Typography sx={styles.title}>Lancer de hache</Typography>
                          <Typography sx={styles.description}>{"Bienvenue dans le monde fascinant du lancer de hache ! Vous avez probablement déjà entendu parler de cette activité qui fait fureur dans les bars à haches et les centres de loisirs."}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                          <Button disableElevation variant="contained" sx={styles.discoverButton} onClick={() => handleDisplayWebSite('https://www.wisplash.fr/lancer-de-hache')}> découvrir</Button>
                          <Button disableElevation variant="contained" sx={styles.knowMoreButton} onClick={() => handleOpenModal('HACHE')}> en savoir +</Button>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                      <Card sx={{
                        p: 0,
                        height: 'auto',
                        boxShadow: theme.shadows[4],
                        '&:hover': {
                          boxShadow: theme.shadows[24],
                          transform: 'scale(102%)'
                        }
                      }}>
                        <Stack sx={{ height: '200px', backgroundColor: '#efebe9', p: 0 }}>
                          <img
                            src={accro}
                            alt='wisplash'
                            loading="lazy"
                            style={{ height: '200px' }} />
                        </Stack>
                        <Stack sx={{ pl: 4, pr: 4, pt: 2 }}>
                          <Typography sx={styles.title}>Accrobranche</Typography>
                          <Typography sx={styles.description}>{"Notre parc acrobatique en hauteur sur le thème de la piraterie offre une vue imprenable sur le lac des vieilles Forges. Avec des parcours adaptés à tous les niveaux, vous pourrez vous amuser tout en vous dépassant"}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                          <Button disableElevation variant="contained" sx={styles.discoverButton} onClick={() => handleDisplayWebSite('https://www.corsaire-volant.fr/')}> découvrir</Button>
                          <Button disableElevation variant="contained" sx={styles.knowMoreButton} onClick={() => handleOpenModal('ACCRO')}> en savoir +</Button>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                      <Card sx={{
                        p: 0,
                        height: 'auto',
                        boxShadow: theme.shadows[4],
                        '&:hover': {
                          boxShadow: theme.shadows[24],
                          transform: 'scale(102%)'
                        }
                      }}>
                        <Stack sx={{ height: '200px', backgroundColor: '#efebe9', p: 0 }}>
                          <img
                            src={elfy}
                            alt='wisplash'
                            loading="lazy"
                            style={{ height: '200px' }} />
                        </Stack>
                        <Stack sx={{ pl: 4, pr: 4, pt: 2 }}>
                          <Typography sx={styles.title}>Elfy Park</Typography>
                          <Typography sx={styles.description}>{"Si vous recherchez des défis plus ludiques, notre parc de loisirs nature sur le thème des lutins, Elfy Park, est l'endroit idéal. Suivez les sentiers et découvrez les cachettes secrètes des lutins et participez à des activités ludiques pour toute la famille."}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                          <Button disableElevation variant="contained" sx={styles.discoverButton} onClick={() => handleDisplayWebSite('https://www.elfypark.fr/')}> découvrir</Button>
                          <Button disableElevation variant="contained" sx={styles.knowMoreButton} onClick={() => handleOpenModal('ELFY')}> en savoir +</Button>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                      <Card sx={{
                        p: 0,
                        height: 'auto',
                        boxShadow: theme.shadows[4],
                        '&:hover': {
                          boxShadow: theme.shadows[24],
                          transform: 'scale(102%)'
                        }
                      }}>
                        <Stack sx={{ height: '200px', backgroundColor: '#efebe9', p: 0 }}>
                          <img
                            src={trotinette}
                            alt='wisplash'
                            loading="lazy"
                            style={{ height: '200px' }} />
                        </Stack>
                        <Stack sx={{ pl: 4, pr: 4, pt: 2 }}>
                          <Typography sx={styles.title}>Trotinette tout terrain electrique</Typography>
                          <Typography sx={styles.description}>{"Venez profiter d’un moment mémorable en Trottinette électrique tout terrain en circulant à proximité du plus grand lac des Ardennes, afin de vous offrir un panorama inoubliable."}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                          <Button disableElevation variant="contained" sx={styles.discoverButton} onClick={() => handleDisplayWebSite('https://www.cap-ardennes-events.fr/activites/nos-trottinettes-electriques/')}> découvrir</Button>
                          <Button disableElevation variant="contained" sx={styles.knowMoreButton} onClick={() => handleOpenModal('TTT')}> en savoir +</Button>
                        </Stack>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ p: 1 }}>
                      <Card sx={{
                        p: 0,
                        height: 'auto',
                        boxShadow: theme.shadows[4],
                        '&:hover': {
                          boxShadow: theme.shadows[24],
                          transform: 'scale(102%)'
                        }
                      }}>
                        <Stack sx={{ height: '200px', backgroundColor: '#efebe9', p: 0 }}>
                          <img
                            src={maroc}
                            alt='wisplash'
                            loading="lazy"
                            style={{ height: '200px' }} />
                        </Stack>
                        <Stack sx={{ pl: 4, pr: 4, pt: 2 }}>
                          <Typography sx={styles.title}>Riad Marrakech</Typography>
                          <Typography sx={styles.description}>{"Vous avez toujours eu le désir d'explorer les splendeurs du Maroc, mais vous cherchez quelqu'un pour vous guider dans la planification de votre voyage ? Ne cherchez plus ! Ardennes Terre d'Aventures et le Riad Palau Marrakech ont uni leurs forces pour rendre vos voyages au Maroc plus faciles que jamais."}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={2} sx={{ p: 4 }}>
                          <Button disableElevation variant="contained" sx={styles.discoverButton} onClick={() => handleDisplayWebSite('https://www.riadpalau-marrakech.com/')}> découvrir</Button>
                          <Button disableElevation variant="contained" sx={styles.knowMoreButton} onClick={() => handleOpenModal('MAROC')}> en savoir +</Button>
                        </Stack>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid><Grid item xs={1} sm={2}></Grid></>
              : 
              <iframe
                src={url}
                width="100%"
                height={window.screen.height}
                allowfullscreen
                frameBorder="0"
                >
              </iframe>
              }
            </Grid>
          }
          
      </Box>
      
      <KnowMoreModal open={open} handleClose={handleClose} activity={activity} />
    </>
  );
}

const styles = {
  discoverButton: {
    width: 150,
    backgroundColor: '#009688',
    borderRadius: 10,
    fontWeight: 'bold',
    textTransform:'uppercase',
    '&:hover': {
      backgroundColor: '#4db6ac',

    }
  },
  knowMoreButton: {
    width: 150,
    borderRadius: 10,
    backgroundColor: '#306e8f',
    fontWeight: 'bold',
    textTransform:'uppercase',
    '&:hover': {
      backgroundColor: 'rgb(6, 66, 98)',
    }
    // border: '1px solid #306e8f',
  },
  title: {
    color: '#212121',
    fontFamily: 'Afacad',
    fontSize: 18,
  }, 
  description:{
    color: '#424949',
    fontFamily: 'Afacad',
    marginTop: 1,
  }
}

export default App;

import { Stack, Typography } from "@mui/material";
const WisplashDescription = () => {
    return (
        <Stack>
        <Typography sx={styles.title}>{"Bienvenue chez Wisplash"}</Typography>
        <Typography sx={styles.H2}>{"Pour le plus grand plaisir de tous, petits et grands !"}</Typography>

        <Typography sx={styles.p}>{"Tout l'été notre équipe vous accueille pour profiter de nos parcours aquatiques gonflable géants : plus de 1 200 m2 composé de 35 modules à partager en famille, entre collègues ou entre amis."}</Typography>
        <Typography sx={styles.p}>{"Situé en pleine nature au lac des Vieilles Forges dans les Ardennes, ce lieu est l'endroit idéal pour faire le plein d'activités aquatiques et de sensations fortes."}</Typography>

        <Typography sx={styles.H3}>{"Jetez-vous à l'eau !"}</Typography>
        <Typography sx={styles.p}>{"Accessible dès 7 ans, accompagné d’un adulte, notre aquapark gonflable est placé sous la surveillance de nos sauveteurs expérimentés. Ils veillent au bon déroulement de votre session pour un amusement en toute sécurité."}</Typography>
        <Typography sx={styles.p}>{"Equipé de votre gilet (obligatoire), vous bénéficiez d'un briefing 'sécurité' avant de vous lancer à l’assaut du parcours !"}</Typography>


</Stack>
    )
};

export default WisplashDescription;

const styles = {
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Afacad',
        color: '#00463E',
        textTransform: 'uppercase',
        margin: 'auto',
        marginBottom: 4,

    },
    H2: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    H3: {
        fontWeight: 'bold',
        fontSize: 22,
        fontStyle: 'italic',
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    p:{
        fontSize: 20,
        marginBottom: 2,
    fontFamily: 'Afacad'

    },
    listeElement: {
        fontSize: 20,
        marginLeft: 4,
        fontFamily: 'Afacad'

    }
}
import { Stack, Typography } from "@mui/material";
const MarocDescription = () => {
    return (
        <Stack>
        <Typography sx={styles.title}>{"Bienvenue au riad palau Marrakech "}</Typography>
        <Typography sx={styles.H2}>{"Riad Palau: Votre Oasis d'Intimité au Cœur de Marrakech"}</Typography>

        <Typography sx={styles.p}>{"Plongez dans une expérience authentique au Riad Palau, où l'intimité rime avec immersion locale. Niché au cœur de Marrakech, notre Riad vous invite à vous détendre au rythme oriental dans un cadre chaleureux et convivial. Avec notre attention portée aux détails et notre ambiance paisible, chaque moment passé au Riad Palau promet une escapade inoubliable, loin de l'agitation quotidienne. Réservez dès maintenant pour vivre une expérience authentique à Marrakech."}</Typography>
        <Typography sx={styles.p}>{"Votre refuge décontracté au cœur de Marrakech. Niché dans le quartier vibrant de Sidi Ben Slimane, à deux pas du célèbre Dar El Bacha et à 15 minutes à pied de la place Jemaa El Fna, notre riad vous offre une escapade authentique et pleine de charme."}</Typography>
        <Typography sx={styles.p}>{"Au Riad Palau, nous mêlons l'hospitalité marocaine à une ambiance décontractée, créant ainsi le lieu idéal pour explorer la magie de Marrakech. Rejoignez-nous pour une expérience où confort, convivialité et authenticité sont les maîtres mots. Nous avons hâte de vous accueillir pour une escapade détendue et pleine de charme dans notre petit coin de paradis. "}</Typography>
</Stack>
    )
};

export default MarocDescription;

const styles = {
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Afacad',
        color: '#00463E',
        textTransform: 'uppercase',
        margin: 'auto',
        marginBottom: 4,

    },
    H2: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    H3: {
        fontWeight: 'bold',
        fontSize: 22,
        fontStyle: 'italic',
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    p:{
        fontSize: 20,
        marginBottom: 2,
    fontFamily: 'Afacad'

    },
    listeElement: {
        fontSize: 20,
        marginLeft: 4,
        fontFamily: 'Afacad'

    }
}
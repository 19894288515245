import { Box, Modal } from "@mui/material"
import CaeDescription from "./Description/CaeDescription";
import AccroDescritpion from "./Description/AccroDescription";
import ElfyDescritpion from "./Description/ElfyDescription";
import HacheDescritpion from "./Description/HacheDescription";
import MarocDescription from "./Description/MarocDescription";
import WisplashDescription from "./Description/WisplashDescription";
import PlageDescription from "./Description/PlageDescription";
import TrotDescription from "./Description/TrotDescription";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    borderRadius: '6px',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    maxHeight: '75vh',
    overflow: 'scroll',
    '@media (max-width: 550px)': { width: '80%' }
};

const KnowMoreModal = ({ open, handleClose, activity}) => {
    return(
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    {activity === 'CAE' && <CaeDescription/>}
                    {activity === 'WISPLASH' && <WisplashDescription/>}
                    {activity === 'HACHE' && <HacheDescritpion/>}
                    {activity === 'ACCRO' && <AccroDescritpion/>}
                    {activity === 'ELFY' && <ElfyDescritpion/>}
                    {activity === 'TTT' && <TrotDescription/>}
                    {activity === 'MAROC' && <MarocDescription/>}
                </Box>
            </Modal>
        </>
    )
};

export default KnowMoreModal;
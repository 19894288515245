import { Stack, Typography } from "@mui/material";
const CaeDescription = () => {
    return (
        <Stack>
            <Typography sx={styles.title}>{"Bienvenue chez Cap Ardennes Events"}</Typography>
            <Typography sx={styles.H2}>{"Votre Aventure Commence au Lac des Vieilles Forges"}</Typography>

            <Typography sx={styles.p}>{"Découvrez les joies des activités nautiques et d'aventure avec Cap Ardennes Events, votre expert en loisirs extérieurs situé sur les rives pittoresques du Lac des Vieilles Forges. Nous proposons une variété d'activités aquatiques et terrestres pour tous les âges et tous les niveaux d'expérience."}</Typography>

            <Typography sx={styles.H3}>{"Nos Activités Excitantes :"}</Typography>

            <Typography sx={styles.listeElement}>{" - Canoë-Kayak : Parcourez le lac en canoë ou kayak, parfait pour une exploration tranquille ou une aventure plus dynamique."}</Typography>
            <Typography sx={styles.listeElement}>{" - Stand Up Paddle (SUP) : Découvrez le plaisir de la glisse en toute sérénité ou entraînez-vous en équilibre sur nos planches de SUP."}</Typography>
            <Typography sx={styles.listeElement}>{" - Stand Up Paddle Géant : Idéal pour les groupes, testez notre paddle géant et travaillez en équipe pour naviguer sur le lac."}</Typography>
            <Typography sx={styles.listeElement}>{" - Vélo sur l'Eau : Une expérience unique, pédalez sur l'eau avec nos vélos aquatiques."}</Typography>
            <Typography sx={styles.listeElement}>{" - Tandem sur l'Eau : Partagez cette expérience en tandem, idéal pour les couples ou les amis."}</Typography>
            <Typography sx={styles.listeElement}>{" - Pédalo : Profitez d'une balade relaxante ou amusante avec nos pédalos, parfaits pour les familles."}</Typography>
            <Typography sx={[styles.listeElement,{ marginBottom: 2}]}>{" - Course d'Orientation : Éveillez votre esprit d'aventure et mettez à l'épreuve votre sens de l'orientation avec notre parcours spécialement conçu."}</Typography>
            <Typography sx={styles.H3}>{"Engagement pour la Qualité et la Sécurité :"}</Typography>
            <Typography sx={styles.p}>{"La sécurité est notre priorité absolue. Toutes nos activités sont encadrées par des professionnels qualifiés et équipées selon les normes de sécurité les plus élevées pour garantir votre bien-être."}</Typography>

            <Typography sx={styles.H3}>{'Pourquoi Nous Choisir ?'}</Typography>
                <Typography sx={styles.listeElement}>{" - Localisation Idéale : Situés au cœur des Ardennes, sur le magnifique Lac des Vieilles Forges."}</Typography>
                <Typography sx={styles.listeElement}>{" - Activités Variées : Pour les amateurs d'aventure, les sportifs, et ceux en quête de détente."}</Typography>
                <Typography sx={[styles.listeElement,{ marginBottom: 2}]}>{" - Expérience Professionnelle : Encadrement par des experts passionnés et attentifs à vos besoins."}</Typography>
                
            <Typography sx={styles.H3}>{"Planifiez Votre Aventure :"}</Typography>
                <Typography sx={styles.p}>{"Venez nous rejoindre pour une journée pleine d'aventure et de plaisir au Lac des Vieilles Forges. Pour plus d'informations et réservations, visitez notre site web [Inclure URL du site] ou contactez-nous au [Inclure numéro de téléphone]."}
            </Typography>
            <Typography sx={styles.p}>
                {"Cap Ardennes Events, où l'aventure et la nature se rencontrent !"}
            </Typography>
    </Stack>
         
    )
}

export default CaeDescription;

const styles = {
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        fontFamily: 'Afacad',
        color: '#00463E',
        textTransform: 'uppercase',
        margin: 'auto',
        marginBottom: 4,

    },
    H2: {
        fontWeight: 'bold',
        fontSize: 24,
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    H3: {
        fontWeight: 'bold',
        fontSize: 22,
        fontStyle: 'italic',
        marginBottom: 2,
        fontFamily: 'Afacad',
        backgroundColor: '#00463E',
        color: '#ffffff',
        padding: "2px 16px",
        width: 'fit-content',

    },
    p:{
        fontSize: 20,
        marginBottom: 2,
    fontFamily: 'Afacad'

    },
    listeElement: {
        fontSize: 20,
        marginLeft: 4,
        fontFamily: 'Afacad'

    }
}